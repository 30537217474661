<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item" @click="logout()">
                <b class="change-login-register-action">Cerrar sesión </b>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column stepper-container login-form-container">
            <p class="title" data-testid="login-title">
              Antes de contiuar es necesario que selecciones un plan y agregues
              un método de pago
            </p>
          </div>
          <div class="column stepper-container payment-message-container">
            <!--
            <div class="year-payment-container">
              <b-switch v-model="yearlyPaymentActive" type="is-success">
                Pago anual
              </b-switch>
            </div>
            -->

            <div class="year-payment-container">
              <b-switch v-model="professionalServicesActive" type="is-success">
                Planes de servicios profesionales
              </b-switch>
            </div>

            <div class="pricing-message">
              El cobro de la primera mensualidad o anualidad del plan
              seleccionado sera realizado una vez terminada la prueba gratuita
              de 3 meses
            </div>

            <div
              class="pricing-cards-container special-margin"
              v-if="!professionalServicesActive"
            >
              <div
                v-bind:class="{ 'selected-price': selectedPlan === 1 }"
                class="single-price-card single-price-card-yellow"
                @click="selectPlan(1)"
              >
                <p class="single-price-title">Plan Básico</p>

                <hr class="single-price-divider" />

                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "$ 499" : "$ 5,988" }}
                </p>
                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                </p>
                <p class="single-price-description">(IVA incluido)</p>

                <ul class="single-price-list">
                  <li>1 usuario incluido</li>
                  <li>1 almacén incluido</li>
                  <li>1 lista de precios</li>
                  <li>1000 timbres mensuales</li>
                </ul>
              </div>

              <div
                v-bind:class="{ 'selected-price': selectedPlan === 2 }"
                class="single-price-card single-price-card-blue"
                @click="selectPlan(2)"
              >
                <p class="single-price-title">Plan profesional</p>

                <hr class="single-price-divider" />

                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "$ 999" : "$11,988" }}
                </p>
                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                </p>
                <p class="single-price-description">(IVA incluido)</p>

                <ul class="single-price-list">
                  <li>1 usuario incluido</li>
                  <li>2 almacén incluido</li>
                  <li>1 lista de precios</li>
                  <li>2000 timbres mensuales</li>
                </ul>
              </div>

              <div
                v-bind:class="{ 'selected-price': selectedPlan === 3 }"
                class="single-price-card single-price-card-green"
                @click="selectPlan(3)"
              >
                <p class="single-price-title">Plan Pro+</p>

                <hr class="single-price-divider" />

                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "$ 3,999.0" : "$ 47,988.0" }}
                </p>
                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                </p>
                <p class="single-price-description">(IVA incluido)</p>

                <ul class="single-price-list">
                  <li>5 usuario incluido</li>
                  <li>2 almacén incluido</li>
                  <li>3 lista de precios</li>
                  <li>5000 timbres mensuales</li>
                </ul>
              </div>

              <div
                v-bind:class="{ 'selected-price': selectedPlan === 4 }"
                class="single-price-card single-price-card-purple"
                @click="selectPlan(4)"
              >
                <p class="single-price-title">Plan Master</p>

                <hr class="single-price-divider" />

                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "$ 16,560" : "$ 19,8720" }}
                </p>
                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                </p>
                <p class="single-price-description">(IVA incluido)</p>

                <ul class="single-price-list">
                  <ul class="single-price-list">
                    <li>10 usuario incluido</li>
                    <li>3 almacén incluido</li>
                    <li>100 lista de precios</li>
                    <li>10000 timbres mensuales</li>
                  </ul>
                </ul>
              </div>
            </div>

            <div
              class="pricing-cards-container special-margin"
              v-if="professionalServicesActive"
            >
              <div
                v-bind:class="{
                  'selected-price': selectedPlan === 5,
                  'selected-price-option-selected': selectedPlan !== null,
                }"
                class="single-price-card single-price-card-yellow"
                @click="selectPlan(5)"
              >
                <p class="single-price-title">Plan Básico Servicios</p>

                <hr class="single-price-divider" />

                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "$ 499" : "$ 5,988" }}
                </p>
                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                </p>
                <p class="single-price-description">(IVA incluido)</p>

                <ul class="single-price-list">
                  <li>1 usuario incluido</li>
                  <li>1 almacén incluido</li>
                  <li>50 timbres mensuales</li>
                </ul>
              </div>

              <div
                v-bind:class="{
                  'selected-price': selectedPlan === 6,
                  'selected-price-option-selected': selectedPlan !== null,
                }"
                class="single-price-card single-price-card-green"
                @click="selectPlan(6)"
              >
                <p class="single-price-title">Plan Servicios</p>

                <hr class="single-price-divider" />

                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "$ 1,500" : "$ 18,000" }}
                </p>
                <p class="single-price-price">
                  {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                </p>
                <p class="single-price-description">(IVA incluido)</p>

                <ul class="single-price-list">
                  <li>5 usuario incluido</li>
                  <li>3 almacén incluido</li>
                  <li>200 timbres mensuales</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="column stepper-container payment-button-start">
            <b-button class="payment-button" @click="openStripeCheckout()"
              >Agregar método de pago</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Punto de venta</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Venta de productos</a></li>
            <li><a>Administración de sucursales</a></li>
            <li><a>Control de pedidos</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import {
  invoicePlanMoth,
  basicPlanMonth,
  profesionalPlanMonth,
  proPlusPlanMonth,
  masterPlanMonth,
  basicServicesPlanMonth,
  proPlusServicesPlanMonth,
} from "../../../utils/env-variables-fns";
// @ is an alias to /src
export default {
  name: "NoPayment",
  components: {},
  data() {
    return {
      subscriptionPlans: [
        {
          price: invoicePlanMoth,
          quantity: 1,
        },
        {
          price: basicPlanMonth,
          quantity: 1,
        },
        {
          price: profesionalPlanMonth,
          quantity: 1,
        },
        {
          price: proPlusPlanMonth,
          quantity: 1,
        },
        {
          price: masterPlanMonth,
          quantity: 1,
        },
        {
          price: basicServicesPlanMonth,
          quantity: 1,
        },
        {
          price: proPlusServicesPlanMonth,
          quantity: 1,
        },
      ],
      invoicePlanMoth: invoicePlanMoth,
      basicPlanMonth: basicPlanMonth,
      profesionalPlanMonth: profesionalPlanMonth,
      proPlusPlanMonth: proPlusPlanMonth,
      masterPlanMonth: masterPlanMonth,
      basicServicesPlanMonth: basicServicesPlanMonth,
      proPlusServicesPlanMonth: proPlusServicesPlanMonth,
      yearlyPaymentActive: false,
      professionalServicesActive: false,
      selectedPlan: null,
    };
  },
  mounted() {
    if (this.companyInformation && this.companyInformation.suscribedPayment) {
      this.$router.push("/Dashboards/Initial").catch(() => {});
    }

    if (this.companyInformation.PLAN_PAGO) {
      if (this.companyInformation.PLAN_PAGO === this.invoicePlanMoth) {
        this.selectedPlan = 0;
      } else if (this.companyInformation.PLAN_PAGO === this.basicPlanMonth) {
        this.selectedPlan = 1;
      } else if (
        this.companyInformation.PLAN_PAGO === this.profesionalPlanMonth
      ) {
        this.selectedPlan = 2;
      } else if (this.companyInformation.PLAN_PAGO === this.proPlusPlanMonth) {
        this.selectedPlan = 3;
      } else if (this.companyInformation.PLAN_PAGO === this.masterPlanMonth) {
        this.selectedPlan = 4;
      } else if (
        this.companyInformation.PLAN_PAGO === this.basicServicesPlanMonth
      ) {
        this.selectedPlan = 5;
      } else if (
        this.companyInformation.PLAN_PAGO === this.proPlusServicesPlanMonth
      ) {
        this.selectedPlan = 6;
      }
    }
  },
  methods: {
    async openStripeCheckout() {
      if (!this.companyInformation._id) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      const response = await this.$store.dispatch("STRIPECHECKOUT", {
        plan: this.subscriptionPlans[this.selectedPlan].price,
        companyId: this.companyInformation._id,
      });
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_self");
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    async logout() {
      this.$store.dispatch("LOGOUT_DATABASE");
      this.$store.dispatch("LOGOUT");
      this.$router.push({ path: "/Login" }).catch(() => {});
    },
  },
  watch: {
    companyInformation() {
      if (this.companyInformation._id) {
        if (
          this.companyInformation &&
          this.companyInformation.suscribedPayment
        ) {
          this.$router.push("/Dashboards/Initials").catch(() => {});
        }
      }
    },
    professionalServicesActive() {
      this.selectedPlan = null;
    },
  },
  computed: {
    userInformation() {
      return this.$store.getters.USER;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
